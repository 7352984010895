<template>
<div class="home">
  <div class="home-pc" v-if="!isMobile">
    <div class="k_header">
      <div class="header_top_logo">
        <img src="../assets/img2/header_logo@2x.png" alt="" />
      </div>
      <div class="slogan">
        <img class="text" src="../assets/img2/header_text@2x.png" alt="" />
        <img class="download" src="../assets/img2/header_icon_download@2x.png" alt="" />
        <!-- 下载app -->
        <div class="scan_code">
          <img class="app_code" src="../assets/img2/code.png" alt="" />
          <div>扫码下载书壳App</div>
        </div>
      </div>
    </div>
    <div class="k_main">
      <div class="box_left">
        <div class="box_left_cont">
          <img class="big_logo_text" src="../assets/img2/banner_logo@2x.png" alt="" />
          <div class="big_logo_desc">
            <div class="pl">你身边行走的图书馆</div>
            <div class="pl">图书回收·5折买书·全场包邮</div>
          </div>
        </div>
        <img class="big_logo" src="../assets/img2/banner_pic@2x.png" alt="" />
      </div>
      <img class="down-arrow" src="../assets/img2/guide@2x.png" alt="" />
    </div>
    <div class="k_section k_section_1 sec_left">
      <div class="product_desc">
        <div class="title">在书壳，连接你我</div>
        <div class="sub_tit">图书共享，旧书循环，爱书人心灵的交流地</div>
      </div>
      <img class="product_img" src="../assets/img2/main_a_pic@2x.png" alt="" />
    </div>
    <div class="k_section k_section_2 sec_right">
      <div class="product_desc">
        <div class="title">在书壳，漫享轻生活</div>
        <div class="sub_tit">图书回收，消毒翻新，好书值得拥有新主人</div>
      </div>
      <img class="product_img" src="../assets/img2/main_b_pic@2x.png" alt="" />
    </div>
    <div class="k_section k_section_3 sec_left">
      <div class="product_desc">
        <div class="title">在书壳，看见更大世界</div>
        <div class="sub_tit">正版书籍，最高5折，书中世界更精彩</div>
      </div>
      <img class="product_img" src="../assets/img2/main_c_pic@2x.png" alt="" />
    </div>
    <div class="k_section k_section_4">
      <img class="logo_download" src="../assets/img2/footer_logo@2x.png" alt="" />
      <div class="btn_group">
        <div class="btn ios_download" @click="download('ios')"> <img src="../assets/img2/footer_but_ios_icon@2x.png" alt="" /> App Store 下载</div>
        <div class="btn android_download" @click="download('android')"> <img src="../assets/img2/footer_but_android_icon@2x.png" alt="" /> Android 下载</div>
        <div class="code_download"> <img src="../assets/img2/footer_but_qr_code_icon@2x.png" alt="" /> </div>
        <div class="scan_code">
          <img class="app_code" src="../assets/img2/code.png" alt="" />
        </div>
      </div>
    </div>
    <div class="k_footer">
      © 2018-2022 Sumend Ltd. 版权所有. <a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备19000001号-4</a>
    </div>
    <div class="topping" @click="backTop" v-show="btnShow">
      <img src="../assets/img2/top@2x.png" alt="">
    </div>
  </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

    <div class="home-mobile" v-else>
      <img class="big_logo" src="../assets/img2/big_logo.png" alt="" />
      <div class="slogan">二手书循环</div>
      <div class="sub_slogan">图书回收，循环共享</div>
      <div class="download" @click="download_m">下载APP</div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data () {
    return {
      isMobile: false,
      btnShow: false
    }
  },
  created () {
    if (this._isMobile()) {
      console.log(this._isMobile())
      this.isMobile = true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  unmounted () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    _isiOS () {
      var UA = navigator.userAgent
      if (UA.match(/iPad/) || UA.match(/iPhone/) || UA.match(/iPod/)) {
        return true
      } else {
        return false
      }
    },
    // 下载
    download (model) {
      if (model === 'ios') {
        window.location.href = 'https://apps.apple.com/cn/app/id1556172721'
      } else {
        window.location.href = 'https://skread.oss-cn-hangzhou.aliyuncs.com/shuke/shuke.apk'
      }
    },
    download_m () {
      if (this._isiOS()) {
        window.location.href = 'https://apps.apple.com/cn/app/id1556172721'
      } else {
        window.location.href = 'https://skread.oss-cn-hangzhou.aliyuncs.com/shuke/shuke.apk'
      }
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop () {
      const that = this
      const timer = setInterval(() => {
        const ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 为了计算距离顶部的高度，当高度大于200显示回顶部图标，小于200则隐藏
    scrollToTop () {
      const that = this
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      console.log('33', that.scrollTop)
      if (that.scrollTop > 500) {
        that.btnShow = true
      } else {
        that.btnShow = false
      }
    }
  }
}
</script>

<style>
   @media screen and (max-width:480px){
    .home {
      background: red;
    }
  }
</style>

<style lang="less" scoped>
  .home {
    background: #ECECEC;
  }

  .k_header {
      // width: 1190px; /** 1720px */
      width: 1720px;
      margin: 0 auto;
      padding: 20px 76px;
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      @media screen and (max-width: 1920px) {
        width: 1190px;
      }
      .header_top_logo {
        img {
          width: 68px;
        }
      }
      .slogan {
        font-size: 12px;
        font-weight: 400;
        color: #5FC1C7;
        position: relative;
        .text {
          width: 245px;
        }
        .download {
          width: 24px;
          margin-left: 24px;
          cursor: pointer;
        }
        .download:hover + .scan_code {
          display: block;
        }
        .scan_code {
          font-size: 16px;
          color: #7B7B7B;
          text-align: center;
          position: absolute;
          right: 0;
          top: 50px;
          display: none;
          .app_code {
            width: 130px;
            display: block;
            margin-bottom: 20px;
          }
        }
      }
    }
    .k_main {
      // width: 1190px;
      width: 1720px;
      margin: 0 auto;
      font-size: 14px;
      text-align: center;
      height: calc(100vh - 84px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 1920px) {
        width: 1190px;
      }
      .box_left {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .box_left_cont {
        margin-right: 40px;
        text-align: right;
        .big_logo_text {
          width: 188px;
        }
        .big_logo_desc {
          margin-top: 24px;
        }
        .pl {
          // font-size: 16px;
          font-size: 32px;
          text-align: right;
          margin-bottom: 10px;
          @media screen and (max-width: 1920px) {
            font-size: 16px;
          }
        }
      }
      .big_logo {
        width: 500px;
        margin-top: 120px;
        margin-bottom: 120px;
      }
      .down-arrow {
        margin-top: 70px;
        margin-bottom: 70px;
        width: 26px;
      }
    }
    .k_section {
      // width: 1190px;
      width: 1720px;
      margin: 0 auto;
      height: 100vh;
      @media screen and (max-width: 1920px) {
        width: 1190px;
      }
      .product_desc {
        margin: 0 100px;
        .title {
          color: #181818;
          // font-size: 25px;
          font-size: 50px;
          font-weight: bold;
          @media screen and (max-width: 1920px) {
            font-size: 25px;
          }
        }
        .sub_tit {
          // width: 420px;
          // font-size: 16px;
          font-size: 32px;
          margin-top: 32px;
          @media screen and (max-width: 1920px) {
            font-size: 16px;
          }
        }
      }
      .product_img {
        width: 473px;
        @media screen and (max-width: 1920px) {
          width: 350px;
        }
      }
    }
    .sec_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // padding: 0 180px;
      box-sizing: border-box;
      text-align: right;
      // @media screen and (max-width: 1920px) {
      // }
    }
    .sec_right {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      // padding: 0 180px;
      box-sizing: border-box;
      text-align: left;
    }
    .k_section_4 {
      text-align: center;
      // padding: 200px 0;
      padding-top: 18%;
      box-sizing: border-box;
      background: #181818;
      width: 100%;
      height:  calc(100vh - 96px);
      .logo_download {
        width: 170px;
      }
      .btn_group {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 1190px;
        left: 50%;
        transform: translateX(-50%);
        .btn {
          width: 145px;
          line-height: 36px;
          border: 2px solid #7B7B7B;
          opacity: 1;
          border-radius: 6px;
          color: #7B7B7B;
          font-size: 12px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin: 42px 10px;
          img {
            width: 16px;
            margin-right: 4px;
          }
        }
        .code_download {
          width: 36px;
          height: 36px;
          border: 2px solid #7B7B7B;
          opacity: 1;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          cursor: pointer;
          img {
            width: 14px;
          }
        }
        .code_download:hover + .scan_code {
          display: block;
        }
        .scan_code {
          position: absolute;
          left: 815px;
          display: none;
          .app_code {
            width: 100px;
            display: block;
          }
        }
      }
    }
    .k_footer {
      text-align: center;
      color: #7B7B7B;
      font-size: 20px;
      padding: 40px 0;
      background: #181818;
      height: 16px;
      @media screen and (max-width: 1920px) {
        font-size: 12px;
      }
      a {
        color: #7B7B7B;
      }
    }
    .topping {
      position: fixed;
      bottom: 100px;
      right: 100px;
      img{
        width: 44px;
      }
    }
  .home-mobile {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45%;
    box-sizing: border-box;
    .big_logo {
      width: 72px;
    }
    .slogan {
      font-size: 24px;
      font-weight: bold;
      margin-top: 24px;
    }
    .sub_slogan {
      font-size: 12px;
      margin-top: 10px;
    }
    .download {
      width: 245px;
      line-height: 44px;
      background: #181818;
      opacity: 1;
      border-radius: 6px;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      margin-top: 64px;
    }
  }
</style>
